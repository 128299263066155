.outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
    font-size: 30px;
    font-weight: 500;
    padding: 50px;
}

.name {
    width: 300px;
    font-size: 20px;
}

.submitBtn {
    width: 300px;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    border: 0;
    background-color: white;
    color: #46579B;
    font-weight: 550;
    font-size: 18px;
    box-shadow: 2px 7px 8px -1px rgba(0,0,0,0.29);
    cursor: pointer;
}

.charCounter {
    font-size: 18px;
    text-align: end;
}

.textField {
    max-width: 750px;
    width: 60%;
}

.openAiCheckbox {
    margin-top: 100px;
    padding: 5px;
    font-size: 15px;
}

.openAiCheckbox .checkbox {
    margin: 2px;
    width: 15px;
    height: 15px;
}