.footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    background-color: #46579B;
    color: white;
}

.footerItem {
    padding: 10px;
    cursor: pointer;
}
