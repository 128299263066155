.all {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background:
            linear-gradient(45deg, #cccccc, #cccccc00),
            linear-gradient(135deg, #46579b, #46579b00),
            linear-gradient(225deg, #46579b, #46579b00),
            linear-gradient(315deg, #46576b, #46576b00);
}

body {
    margin: 0;
}
