.outerDiv {
    max-width: 650px;
    margin: 0 auto;
    padding: 20px;
}

.letterDiv {
    border: 1px solid #ccc;
}

.innerDiv {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 200px;
    background-color: white;
}

.paragraph {
    margin-bottom: 10px
}

.downloadBtn {
    width: fit-content;
    margin-top: 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border-width: 1px;
    border-color: #46579B;
    border-style: ridge;
    color: #46579B;
    font-weight: 550;
    font-size: 18px;
    box-shadow: 2px 7px 8px -1px rgba(0,0,0,0.29);
    cursor: pointer;
}