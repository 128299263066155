.logo {
    margin: 20px;
    background-image: url("../images/logo.png");
    align-self: flex-start;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    cursor: pointer;
    height: 100px;
}

.header {
    background-color: #46579b;
}